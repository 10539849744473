<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="md" class="custom-header">
        <ion-buttons slot="start" @click="router.go(-1)">
          <ion-button v-if="!loading">
            <ion-icon slot="icon-only" :icon="arrowBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title v-if="!loading">
          <strong>{{ archive.job_term.name }}</strong> #{{ archive.id }}
        </ion-title>

        <ion-title v-else>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content color="tertiary">
      <div class="custom-content">
        <ion-label class="custom-content__client" v-if="archive">
          <ion-icon :icon="personOutline" />
          <strong>{{ archive.client.name }}</strong>
          <ion-badge color="light" slot="end"
            >#{{ archive.client.id }}</ion-badge
          >
        </ion-label>
        <div class="notes" v-if="archive && archive.notes">
          <ion-icon :icon="warningOutline" />
          <span>
            <strong>Oharra:</strong><br />
            {{ archive.notes }}</span
          >
        </div>
        <job-complete-form
          v-if="archive"
          :job="archive.id"
          :term="archive.job_term_id"
          :client="archive.client_id"
          :delete="deleteJob"
          @jobAdded="jobAdded"
          @jobDeleted="jobDeleted"
        />
      </div>

      <div v-if="!loading">
        <ion-list
          v-if="archive"
          style="padding-top: 0; padding-bottom: 0;"
          mode="ios"
          lines="full"
          inset="false"
        >
          <ion-item
            v-for="item in archive.jobs_archive"
            :key="item"
            color="transparent"
            lines="full"
          >
            <ion-label>
              <h5>
                <strong>{{ item.work_date }}</strong>
              </h5>
              <p v-if="item.notes" class="notes__job">
                <ion-icon :icon="warning"></ion-icon>
                {{ item.notes }}
              </p>
            </ion-label>

            <ion-button color="danger" @click="deleteHandle(item)">
              <ion-icon :icon="trashOutline"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </div>

      <ion-list
        v-else
        style="padding-top: 0; padding-bottom: 0;"
        mode="ios"
        lines="full"
        inset="false"
      >
        <ion-item
          v-for="item in 6"
          :key="item"
          color="transparent"
          lines="full"
        >
          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 100%"
              ></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonBadge,
  IonIcon,
  IonList,
  IonSkeletonText,
} from "@ionic/vue";

import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import axios from "axios";
import JobCompleteForm from "@/components/forms/JobCompleteForm.vue";

import {
  personAddOutline,
  arrowBackOutline,
  chatbubblesOutline,
  personOutline,
  documentTextOutline,
  trashOutline,
  warningOutline,
  warning,
} from "ionicons/icons";

import { NativePageTransitions } from "@ionic-native/native-page-transitions";

export default {
  name: "JobsArchive",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonButtons,
    IonBadge,
    IonIcon,
    IonList,
    IonSkeletonText,
    JobCompleteForm,
  },
  watch: {
    $route(to, from, next) {
      let options = {
        direction: "right",
        duration: 400,
        slowdownfactor: 0,
        slidePixels: 0,
        iosdelay: 0,
        androiddelay: 0,
        fixedPixelsTop: 0,
        fixedPixelsBottom: 0,
      };

      NativePageTransitions.slide(options).then(() => next());
    },
  },
  mounted() {
    let options = {
      direction: "left",
      duration: 400,
      slowdownfactor: 5,
      slidePixels: 0,
      iosdelay: 0,
      androiddelay: 0,
      fixedPixelsTop: 0,
      fixedPixelsBottom: 0,
    };
    NativePageTransitions.slide(options);
  },

  setup() {
    const loading = ref(true);
    const archive = ref(null);
    const route = useRoute();
    const router = useRouter();
    const deleteJob = ref(null);
    const api = ref(process.env.VUE_APP_API);

    onMounted(async () => {
      getJobArchive();
    });

    const getJobArchive = async () => {
      axios.get(`${api.value}job/${route.params.id}`).then((res) => {
        archive.value = res.data.result[0];
        archive.value.jobs_archive.sort(function(a, b) {
          return new Date(b.work_date) - new Date(a.work_date);
        });
        loading.value = false;
      });
    };

    const jobAdded = (add) => {
      archive.value.jobs_archive.unshift(add);
      archive.value.jobs_archive.sort(function(a, b) {
        return new Date(b.work_date) - new Date(a.work_date);
      });
    };

    const jobDeleted = () => {
      getJobArchive();
    };

    const deleteHandle = (item) => {
      let del = confirm("Lana ezabatu nahi al duzu?");
      if (del) deleteJob.value = item.id;
    };

    return {
      loading,
      archive,
      router,
      personAddOutline,
      personOutline,
      arrowBackOutline,
      documentTextOutline,
      chatbubblesOutline,
      warning,
      trashOutline,
      warningOutline,
      jobAdded,
      jobDeleted,
      deleteHandle,
      deleteJob,
    };
  },
};
</script>

<style lang="scss">
.custom-content {
  padding: 20px;
  &__client {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 30px;
    ion-icon {
      font-size: 22px;
      margin-right: 10px;
    }
    strong {
      margin-right: 10px;
    }
  }
}

.archive {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 !important;
}

ion-segment {
  background: white;
}

ion-subtitle {
  margin: 0 20px;
}

.notes {
  display: flex;
  align-items: flex-start;
  background: lighten(#ffca22, 30%);
  padding: 10px;
  font-size: 14px;
  border-radius: 3px;
  &__job {
    display: flex;
    align-items: center;
    font-size: 15px;
    ion-icon {
      margin-right: 5px;
    }
  }
  ion-icon {
    font-size: 20px;
    width: 100%;
    max-width: 40px;
    margin-right: 5px;
  }
}
</style>
