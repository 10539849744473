<template>
  <div id="container">
    <div class="form-content">
      <ion-item>
        <ion-label position="floating">Data</ion-label>
        <ion-datetime
          display-format="MM/DD/YYYY"
          min="1994-01-01"
          mode="ios"
          :value="workDate"
          :max="maxDate"
          @ionChange="workDate = $event.target.value"
        >
        </ion-datetime>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Oharrak</ion-label>
        <ion-textarea :value="notes" @ionInput="notes = $event.target.value">
        </ion-textarea>
      </ion-item>
    </div>

    <ion-button @click="saveHandle" expand="block" mode="ios" size="medium"
      >Egina bezala markatu
    </ion-button>

    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-button
          @click="completeHandle(jobId)"
          expand="block"
          mode="ios"
          color="dark"
          size="medium"
          ><strong>Lana amaitu</strong>
        </ion-button>
      </ion-toolbar>
    </ion-footer>

    <messages v-if="toast" :toast="toast" @closeToast="closeToast"></messages>
  </div>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonButton,
  IonDatetime,
  loadingController,
} from "@ionic/vue";

import { useStore } from "vuex";

import axios from "axios";
import { ref, onMounted, watch, computed } from "vue";

import errors from "@/middlewares/errors.js";
import Messages from "@/components/Messages.vue";

export default {
  name: "JobCompleteForm",
  components: {
    IonItem,
    IonLabel,
    IonButton,
    IonDatetime,
    Messages,
  },
  props: {
    job: {
      type: String,
      required: true,
    },
    term: {
      type: String,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
    delete: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const jobId = ref(props.job);
    const clientId = ref(props.client);
    const jobTermId = ref(props.term);
    const workDate = ref(null);
    const notes = ref(null);
    const toast = ref(null);
    const maxDate = ref(null);
    const deleteJob = ref(null);
    const api = ref(process.env.VUE_APP_API);

    const closeToast = () => {
      toast.value = null;
    };

    onMounted(() => {
      let date = new Date();
      maxDate.value =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);
    });

    const changeDelete = computed(() => (deleteJob.value = props.delete));

    watch(changeDelete, (id) => {
      deleteHandle(id);
    });

    const saveHandle = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        mode: "ios",
      });

      await loading.present();

      if (!workDate.value) {
        toast.value = {
          type: "danger",
          message: "Eremu guztiak bete",
        };

        loading.dismiss();

        return true;
      }

      let current_datetime = new Date(workDate.value);
      let formatted_date =
        current_datetime.getFullYear() +
        "-" +
        ("0" + (current_datetime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + current_datetime.getDate()).slice(-2) +
        " " +
        ("0" + current_datetime.getHours()).slice(-2) +
        ":" +
        ("0" + current_datetime.getMinutes()).slice(-2) +
        ":" +
        ("0" + current_datetime.getSeconds()).slice(-2);

      let params = {
        job_id: jobId.value,
        client_id: clientId.value,
        job_term_id: jobTermId.value,
        work_date: formatted_date,
        notes: notes.value,
      };

      axios
        .post(`${api.value}jobs-archive`, params)
        .then((res) => {
          if (res.data.error) {
            toast.value = {
              type: "danger",
              message: `Erroreak errepasatu:
                ${errors(res.data.error)}`,
            };
            loading.dismiss();
            return true;
          }

          emit("jobAdded", params);
          store.dispatch("_jobsReload");

          toast.value = {
            type: "success",
            message: "Lanaren data zehaztu da",
          };

          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    const deleteHandle = async (jobId) => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        mode: "ios",
      });

      await loading.present();

      axios
        .delete(`${api.value}jobs-archive/${jobId}`)
        .then(() => {
          emit("jobDeleted", jobId);
          store.dispatch("_jobsReload");
          toast.value = {
            type: "success",
            message: "Lana ezabatu da",
          };
          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    const completeHandle = async (jobId) => {
      let end = confirm("Lan hau bukatutzat eman nahi al duzu?");
      if (!end) return true;

      const loading = await loadingController.create({
        mode: "ios",
      });

      await loading.present();
      let params = {
        id: jobId,
        completed: true,
      };

      axios
        .post(`${api.value}jobs/complete`, params)
        .then(() => {
          store.dispatch("_jobsReload");
          toast.value = {
            type: "success",
            message: "Lana amaitutzat eman da",
          };
          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    return {
      saveHandle,
      deleteHandle,
      completeHandle,
      jobId,
      clientId,
      workDate,
      notes,
      toast,
      closeToast,
      maxDate,
    };
  },
};
</script>

<style lang="scss">
.form-content {
  margin: 0 0 30px 0;
}
</style>
